import "../legal.css";
import "./faq.scss";

import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/SectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";

import Accordion from "../../../components/accordion/accordion";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout className="invertColors noBg" id="faq">
			<Seo title={t("Aviso legal")} />
			<StaticImage
				src={`../../../images/faqBg.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding
				className="darkText"
				orbitalText={t("FAQS")}
				title={t("Preguntas frecuentes")}
				subtitle={t("¿Podemos ayudarte?")}
				scrollText={t("Ver preguntas")}
			/>

			<div className="legalBody section invertColors" id="faqBody">
				<div>
					<h1>
						<Trans>Reservas</Trans>
					</h1>

					<div className="faqsContainer">
						<div>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>¿Cómo hago mi reserva?</Trans>
									</p>
								}
							>
								<p>
									<Trans i18nKey={"faq1"}>
										Nos alegra muchísimo que quieras vivir la experiencia Begin.
										<br />
										Para reservar simplemente accede al{" "}
										<Link to="/reserva">
											<u>apartado de reservas</u>
										</Link>{" "}
										de nuestra web y selecciona el día, hora y número de
										comensales. En este apartado te aparecerá la disponibilidad
										real del restaurante 🌿
										<br />
										En el caso de no estar disponible el día que tenías en
										mente, puedes reservar otro día.
										<br />
										Si hubiera alguna cancelación, la mesa cancelada se
										liberaría automáticamente y se podría reservar desde la web
										como cualquier otra reserva. No tenemos lista de espera.
										<br />
										Además, te recordamos que muy pronto abriremos nuestra
										terraza exterior, por lo que el número de reservas aumentará
										:)
									</Trans>
								</p>
							</Accordion>

							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>¿Cómo cancelo mi reserva?</Trans>
									</p>
								}
							>
								<p>
									<Trans i18nKey="faq2">
										Si quieres cancelar una reserva recuerda que puedes hacerlo
										desde el correo o el sms que te llega al hacer la reserva.
										<br />
										Esperamos verte muy pronto volviendo al Origen 😌🌱
									</Trans>
								</p>
							</Accordion>

							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>¿Cómo puedo modificar mi reserva?</Trans>
									</p>
								}
							>
								<p>
									<Trans i18nKey="faq3">
										Si quieres cambiar una reserva de día u hora contáctanos
										indicando qué fecha y hora estaba hecha la reserva original,
										además del nombre de quién la hizo.
										<br />
										Por otro lado, necesitamos saber la fecha u hora (o ambas)
										de la nueva reserva que quieres. Intentaremos hacer lo
										posible, pero el cambio estará sujeto a disponibilidad.
									</Trans>
								</p>
							</Accordion>
						</div>
						<div>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>¿Puedo ampliar mi reserva?</Trans>
									</p>
								}
							>
								<p>
									<Trans i18nKey="faq4">
										Si quieres ampliar una reserva, contáctanos indicando qué
										fecha y hora estaba hecha la reserva original, además del
										nombre de quién la hizo.
										<br />
										Por otro lado, necesitamos saber el nuevo número de
										comensales. Intentaremos hacer lo posible, pero el cambio
										estará sujeto a disponibilidad.
									</Trans>
								</p>
							</Accordion>

							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>Tengo dudas acerca de mi reserva</Trans>
									</p>
								}
							>
								<p>
									<Trans>
										Simplemente contáctanos a través del chat (abajo a la
										derecha) y cuéntanos tus dudas o comentarios, estaremos
										encantados de poder ayudarte.
									</Trans>
								</p>
							</Accordion>
						</div>
					</div>
				</div>

				<div>
					<h1>
						<Trans>Otras preguntas</Trans>
					</h1>

					<div className="faqsContainer">
						<div>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>Alérgenos</Trans>
									</p>
								}
							>
								<p>
									<Trans i18nKey={"FAQAllergens"}>
										En el restaurante contamos con toda la lista de alérgenos.
										Por otro lado, contamos con opciones veganas y vegetarianas.
										<br />
										Podéis consultar los platos en el apartado{" "}
										<Link to="/menus">
											<u>carta</u>
										</Link>{" "}
										de nuestra web.
									</Trans>
								</p>
							</Accordion>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>Colaboración</Trans>
									</p>
								}
							>
								<p>
									<Trans>
										Para colaborar con nosotros, contáctanos a través de{" "}
										<Link to="/contacto/">
											<u>este formulario</u>
										</Link>
										.
									</Trans>
								</p>
							</Accordion>
						</div>
						<div>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>Mascotas</Trans>
									</p>
								}
							>
								<p>
									<Trans>
										De momento no podéis traer a vuestros amigos peludos al
										restaurante, salvo en terraza exterior donde son más que
										bienvenidos 🐶
									</Trans>
								</p>
							</Accordion>
							<Accordion
								defaultOpen={false}
								title={
									<p className="h2">
										<Trans>Otro</Trans>
									</p>
								}
							>
								<p>
									<Trans>
										Puedes consultarnos cualquier otra duda o comentario a
										través del{" "}
										<Link to="/contacto/">
											<u>formulario de contacto</u>
										</Link>{" "}
										o de nuestro chat (abajo a la derecha).
									</Trans>
								</p>
							</Accordion>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
