// main imports
import * as React from "react";

// plugin imports

// style imports
import "./accordion.scss";

// svg imports

// components imports

// component

export default function Accordion({ title, id, children, defaultOpen = true }) {
	const [open, setOpen] = React.useState(defaultOpen);

	const [initialMount, setInitialMount] = React.useState(true);
	const [height, setHeight] = React.useState(0);
	const maxHeightRef = React.useRef(null);
	// console.log(height);

	const handleClick = () => {
		setOpen((prevState) => !prevState);
	};

	const redimensionSection = React.useCallback(() => {
		if (!open) {
			setHeight(0);
		} else {
			setHeight(maxHeightRef?.current?.scrollHeight);
		}
	}, [open]);

	React.useEffect(() => {
		let initialMountTimeout = setTimeout(() => setInitialMount(false), 200); // No animations on first second after mount

		return () => {
			clearTimeout(initialMountTimeout);
		};
	}, []);

	React.useEffect(() => {
		setHeight(maxHeightRef?.current?.scrollHeight);
	}, [maxHeightRef]);

	React.useEffect(() => {
		let redimensionTiemout = setTimeout(() => redimensionSection(), 100);
		return () => {
			clearTimeout(redimensionTiemout);
		};
	}, [open, redimensionSection]);

	React.useEffect(() => {
		if (typeof window != undefined) {
			window.addEventListener("resize", redimensionSection);
			return () => window.removeEventListener("resize", redimensionSection);
		}
	}, [redimensionSection]);

	return (
		<div className={`accordion ${open ? "open" : ""}`}>
			<button className="accordionTitle" onClick={handleClick}>
				{title}
			</button>
			<div
				ref={maxHeightRef}
				style={{
					maxHeight: height,
				}}
				className={`accordionContainer  ${initialMount ? "noAnimation" : ""}`}
			>
				<div className="accordionChildren">{children}</div>
			</div>
		</div>
	);
}
